import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableBeliSparepart } from "../../../components/ShowTable";
import { Loader, usePagination, ButtonModifier } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import { formatDate } from "../../../constants/helper";

const TampilBeliSparepart = () => {
  const { user, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [noBeliSparepart, setNoBeliSparepart] = useState("");
  const [tanggalBeliSparepart, setTanggalBeliSparepart] = useState("");
  const [tglBeliSparepartDate, setTglBeliSparepartDate] = useState();
  const [kodeSupplierSparepart, setKodeSupplierSparepart] = useState("");
  const [jumlahBeliSparepart, setJumlahBeliSparepart] = useState(0);
  const [isPost, setIsPost] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [daftarStokSparepartsData, setDaftarStokSparepartsData] = useState([]);
  const navigate = useNavigate();
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADM" && isPost === false;
  let isEditableAdmin =
    user.tipeUser === "ADM" &&
    tglBeliSparepartDate?.getFullYear() === today.getFullYear() &&
    tglBeliSparepartDate?.getMonth() === today.getMonth() &&
    tglBeliSparepartDate?.getDate() === today.getDate() &&
    isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = daftarStokSparepartsData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const count = Math.ceil(daftarStokSparepartsData.length / PER_PAGE);
  const _DATA = usePagination(daftarStokSparepartsData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    id && getBeliSparepartById();
  }, [id]);

  const getBeliSparepartById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/beliSpareparts/${id}`, {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setNoBeliSparepart(response.data.noBeliSparepart);
      setTanggalBeliSparepart(formatDate(response.data.tanggalBeliSparepart));
      setJumlahBeliSparepart(response.data.jumlahBeliSparepart);
      setKodeSupplierSparepart(response.data.supplierSparepart);
      setIsPost(response.data.isPost);
      setKodeCOA(response.data.COA);
      setTglBeliSparepartDate(new Date(response.data.tanggalBeliSparepart));

      const response2 = await axios.post(
        `${tempUrl}/daftarStokSparepartsByNoBeliSparepart`,
        {
          noBeliSparepart: response.data.noBeliSparepart,
          id: user._id,
          token: user.token,
        }
      );
      console.log(response2.data);
      setDaftarStokSparepartsData(response2.data);
    }
  };

  const deleteBeliSparepart = async (id) => {
    try {
      setLoading(true);
      for (let daftarStokSparepart of daftarStokSparepartsData) {
        await axios.post(
          `${tempUrl}/deleteDaftarStokSparepart/${daftarStokSparepart._id}`,
          {
            id: user._id,
            token: user.token,
          }
        );
      }
      await axios.post(`${tempUrl}/deleteBeliSparepart/${id}`, {
        id: user._id,
        token: user.token,
      });
      setLoading(false);
      navigate("/daftarBeliSparepart");
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarBeliSparepart")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Sparepart</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Beli Sparepart
        </Typography>
        {isEditable && (
          <Box sx={buttonModifierContainer}>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarBeliSparepart/beliSparepart/${id}/tambahBeliSparepartChild`}
              editLink={`/daftarBeliSparepart/beliSparepart/${id}/edit`}
              deleteUser={deleteBeliSparepart}
              nameUser={noBeliSparepart}
            />
          </Box>
        )}
        <Divider sx={dividerStyle} />
        <Divider sx={{ marginBottom: 2 }} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nomor :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noBeliSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode Supplier :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeSupplierSparepart.kodeSupplierSparepart} - ${kodeSupplierSparepart.namaSupplierSparepart}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jumlah :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={jumlahBeliSparepart.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tanggalBeliSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    COA :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeCOA.kodeCOA} - ${kodeCOA.namaCOA}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={tableContainer}>
          <ShowTableBeliSparepart id={id} currentPosts={currentPosts} />
        </Box>
        <Box sx={tableContainer}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            color="primary"
            size={screenSize <= 600 ? "small" : "large"}
          />
        </Box>
      </Box>
    </>
  );
};

export default TampilBeliSparepart;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../../../constants/helper";
import { AuthContext } from "../../../../../contexts/AuthContext";
import {
  tempUrl,
  useStateContext,
} from "../../../../../contexts/ContextProvider";
import { ShowTableGroupSparepart } from "../../../../../components/ShowTable";
import { FetchErrorHandling } from "../../../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const TampilGroupSparepart = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [isGroupSparepartExist, setIsGroupSparepartExist] = useState(false);
  const [kodeGroupSparepart, setKodeGroupSparepart] = useState("");
  const [namaGroupSparepart, setNamaGroupSparepart] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [groupSparepartsData, setGroupSparepartsData] = useState([]);
  const navigate = useNavigate();

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "Kode GroupSparepart", field: "kodeGroupSparepart" },
    { title: "Nama GroupSparepart", field: "namaGroupSparepart" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getGroupSparepartById() : setIsGroupSparepartExist(false);
  }, [id]);

  useEffect(() => {
    getGroupSparepartsData();
  }, [page, searchTerm]);

  const getGroupSparepartsData = async () => {
    setLoading(true);
    try {
      const allGroupSpareparts = await axios.post(
        `${tempUrl}/groupSparepartsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setGroupSparepartsData(allGroupSpareparts.data.groupSpareparts);
      setPage(allGroupSpareparts.data.page);
      setPages(allGroupSpareparts.data.totalPage);
      setRows(allGroupSpareparts.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getGroupSparepartsDataForDoc = async (isPdf, isExcel) => {
    setLoading(true);
    const allGroupSparepartsForDoc = await axios.post(
      `${tempUrl}/groupSparepartsForDoc`,
      {
        id: user._id,
        token: user.token,
      }
    );
    setLoading(false);
    if (isPdf) {
      downloadPdf(allGroupSparepartsForDoc.data);
    } else if (isExcel) {
      downloadExcel(allGroupSparepartsForDoc.data);
    }
  };

  const getGroupSparepartById = async () => {
    setLoading(true);
    if (id) {
      const pickedGroupSparepart = await axios.post(
        `${tempUrl}/groupSpareparts/${id}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setIsGroupSparepartExist(true);
      setKodeGroupSparepart(pickedGroupSparepart.data.kodeGroupSparepart);
      setNamaGroupSparepart(pickedGroupSparepart.data.namaGroupSparepart);
    }
    setLoading(false);
  };

  const deleteGroupSparepart = async (id) => {
    const findSparepartByKodeGroupSparepart = await axios.post(
      `${tempUrl}/sparepartByKodeGroupSparepart`,
      {
        kodeGroupSparepart,
        id: user._id,
        token: user.token,
      }
    );
    if (findSparepartByKodeGroupSparepart.data.length > 0) {
      // There's Record -> Forbid Delete
      alert(
        `Group Sparepart: ${findSparepartByKodeGroupSparepart.data[0].namaGroupSparepart} tidak bisa dihapus karena sudah ada data!`
      );
    } else {
      // No Record Found -> Delete
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/deleteGroupSparepart/${id}`, {
          id: user._id,
          token: user.token,
        });
        setKodeGroupSparepart("");
        setNamaGroupSparepart("");
        setLoading(false);
        navigate("/groupSparepart");
        getGroupSparepartsData();
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
  };

  const downloadPdf = (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Group Sparepart`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `GroupSparepart`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarGroupSparepart.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Master</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Group Sparepart
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => getGroupSparepartsDataForDoc(true, false)}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getGroupSparepartsDataForDoc(false, true)}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isGroupSparepartExist}
          addLink={`/groupSparepart/tambahGroupSparepart`}
          editLink={`/groupSparepart/${id}/edit`}
          deleteUser={deleteGroupSparepart}
          nameUser={kodeGroupSparepart}
        />
      </Box>
      <Divider sx={dividerStyle} />
      {isGroupSparepartExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Group Sparepart :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={kodeGroupSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Group Sparepart :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaGroupSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableGroupSparepart currentPosts={groupSparepartsData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilGroupSparepart;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const textFieldStyle = {
  display: "flex",
  mt: 4,
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  ButtonGroup,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Col, Row, Form } from "react-bootstrap";

const TampilSetting = () => {
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const { user, setting, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Box>
      <Typography color="#757575">Utility</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Setting
      </Typography>
      <Box sx={buttonModifierContainer}>
        <ButtonGroup variant="contained">
          <Button
            color="primary"
            startIcon={<EditIcon />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              navigate(`/setting/${setting._id}/edit`);
            }}
          >
            Ubah
          </Button>
        </ButtonGroup>
      </Box>
      <Divider sx={dividerStyle} />
      <Box sx={contentContainer}>
        {/* Data Perusahaan */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA PERUSAHAAN
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Perusahaan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.namaPerusahaan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lokasi Perusahaan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.lokasiPerusahaan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Kota Perusahaan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.kotaPerusahaan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Lokasi SP :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.lokasiSP} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      No. Telp :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.noTelpPerusahaan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Eksekutor :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.namaKolektor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nama Pemilik :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.namaPemilik} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      PPN Dipisah (true/false) :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.ppnDipisah} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Alamat Pemilik :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.alamatPemilik} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pekerjaan Pemilik :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.pekerjaanPemilik} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Kode Eksekutor :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.kodeKolektor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Biaya Tarik :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.biayaTarikSetting}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Denda :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.dendaSetting} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Denda (%) :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={`${setting.dendaPersenSetting} %`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Jemputan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.jemputanSetting} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Toleransi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.toleransiSetting} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Max. Mundur Angsuran :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={`${setting.maxMundurAngsuranSetting} hari`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Max. Potongan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.maxPotonganSetting}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Password Potongan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.passwordPotonganSetting}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      PPN :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.PPN} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Supplier Penarikan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.supplierPenarikan}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Nilai Point Penarikan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.nilaiPointSetting}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Cabang Konsolidasi :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.kodeCabangKonsolidasi}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        {/* Data GROUP COA */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA GROUP COA
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Aktiva Lancar :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.groupAktivaLancar}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Aktiva Tetap :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.groupAktivaTetap} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Kewajiban Hutang :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.groupKewajibanHutang}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Modal Saham :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.groupModalSaham} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Pendapatan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.groupPendapatan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Biaya Operasional :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.groupBiayaOperasional}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Pendapatan Non Operasional :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.groupPendapatanNonOperasional}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Group Biaya Non Operasional :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.groupBiayaNonOperasional}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>

        {/* Data COA */}
        <Paper elevation={6} sx={mainContainer}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA COA
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Uang Tunai Kasir :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.uangTunaiKasir} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Bank July :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.bankJuly} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Piutang Dagang :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.piutangDagang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Persediaan Motor Baru :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.persediaanMotorBaru}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Persediaan Motor Bekas :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.persediaanMotorBekas}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Persediaan Sparepart :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.persediaanSparepart}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Modal dan Saham :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.modalDanSaham} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Laba Rugi Periode Berjalan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.labaRugiPeriodeBerjalan}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Laba Rugi Ditahan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.labaRugiDitahan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Penjualan Motor :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.penjualanMotor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Penjualan Sparepart :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.penjualanSparepart}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Potongan Penjualan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.potonganPenjualan}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pendapatan Bunga Angsuran :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.pendapatanBungaAngsuran}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pendapatan Denda :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.pendapatanDenda} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      HPP Motor :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.hppMotor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      HPP Sparepart :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.hppSparepart} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Account Pembalance :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.accountPembalance}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      COA Bank :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.coaBank} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      COA Kas :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control value={setting.coaKas} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pendapatan Jemputan :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.pendapatanJemputan}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="5" style={textRight}>
                      Pendapatan Biaya Tarik :
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        value={setting.pendapatanBiayaTarik}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default TampilSetting;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const textFieldStyle = {
  display: "flex",
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

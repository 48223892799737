import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";
import { NumericFormat } from "react-number-format";

const TambahBeliChildSparepart = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [cabangInput, setCabangInput] = useState("");
  const [kodeSparepart, setKodeSparepart] = useState("");
  const [namaSparepart, setNamaSparepart] = useState("");
  const [satuanSparepart, setSatuanSparepart] = useState("");
  const [quantitySparepart, setQuantitySparepart] = useState("");
  const [hargaSparepart, setHargaSparepart] = useState("");
  const [discountSparepart, setDiscountSparepart] = useState("");
  const [potonganSparepart, setPotonganSparepart] = useState("");

  const [tanggalBeliSparepart, setTanggalBeliSparepart] = useState("");
  const [supplierSparepartId, setSupplierSparepartId] = useState("");
  const [kodeSupplierSparepart, setKodeSupplierSparepart] = useState("");
  const [namaSupplierSparepart, setNamaSupplierSparepart] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [allCabang, setAllCabang] = useState([]);
  const [spareparts, setSpareparts] = useState([]);
  const [beliSpareparts, setBeliSpareparts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  const sparepartOptions = spareparts.map((sparepart) => ({
    label: `${sparepart.kodeSparepart} | ${sparepart.namaSparepart}`,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getAllCabang();
    getSpareparts();
    getBeliSpareparts();
    getSparepartBeliSparepart();
  }, []);

  const getAllCabang = async () => {
    setLoading(true);
    const allCabang = await axios.post(`${tempUrl}/cabangs`, {
      id: user._id,
      token: user.token,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const getSpareparts = async () => {
    setLoading(true);
    const allSpareparts = await axios.post(`${tempUrl}/spareparts`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setSpareparts(allSpareparts.data);
    setLoading(false);
  };

  const getSparepart = async (idSparepart) => {
    const allSparepartsByKode = await axios.post(`${tempUrl}/sparepartsKode`, {
      kodeSparepart: idSparepart,
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    if (allSparepartsByKode.data) {
      setNamaSparepart(allSparepartsByKode.data.namaSparepart);
      setSatuanSparepart(allSparepartsByKode.data.satuanSparepart);
    }
    setKodeSparepart(idSparepart);
  };

  const getSparepartBeliSparepart = async () => {
    setLoading(true);
    const allBeliSpareparts = await axios.post(
      `${tempUrl}/beliSpareparts/${id}`,
      {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      }
    );
    setTanggalBeliSparepart(allBeliSpareparts.data.tanggalBeliSparepart);
    setSupplierSparepartId(allBeliSpareparts.data.supplierSparepart._id);
    setKodeSupplierSparepart(
      allBeliSpareparts.data.supplierSparepart.kodeSupplierSparepart
    );
    setNamaSupplierSparepart(
      allBeliSpareparts.data.supplierSparepart.namaSupplierSparepart
    );

    setLoading(false);
  };

  const getBeliSpareparts = async () => {
    setLoading(true);
    const pickedBeliSparepart = await axios.post(
      `${tempUrl}/beliSpareparts/${id}`,
      {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      }
    );
    setBeliSpareparts(pickedBeliSparepart.data);
    setLoading(false);
  };

  const saveBeliSparepartChild = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    // Get Beli Sparepart
    const response = await axios.post(`${tempUrl}/beliSpareparts/${id}`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    // Get Sparepart/Merk
    const getSparepart = await axios.post(`${tempUrl}/sparepartsKode`, {
      kodeCabang: user.cabang._id,
      kodeSparepart,
      id: user._id,
      token: user.token,
    });

    if (
      cabangInput.length === 0 ||
      kodeSparepart.length === 0 ||
      quantitySparepart.length === 0 ||
      hargaSparepart.length === 0
    ) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        // Get BeliSparepart
        const getBeliSparepart = await axios.post(
          `${tempUrl}/beliSpareparts/${id}`,
          {
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          }
        );
        // Save Daftar Stok Sparepart
        const tempDaftarStokSparepart = await axios.post(
          `${tempUrl}/saveDaftarStokSparepart`,
          {
            noBeliSparepart: beliSpareparts.noBeliSparepart,
            tanggalBeliSparepart,
            supplierSparepartId,
            kodeSupplierSparepart,
            namaSupplierSparepart,
            kodeSparepart: getSparepart.data.kodeSparepart,
            namaSparepart: getSparepart.data.namaSparepart,
            satuanSparepart: getSparepart.data.satuanSparepart,
            quantitySparepart: quantitySparepart.replace(/,/g, ""),
            hargaSparepart: hargaSparepart.replace(/,/g, ""),
            discountSparepart:
              discountSparepart.length !== 0
                ? discountSparepart.replace(/,/g, "")
                : 0,
            potonganSparepart: potonganSparepart.length
              ? potonganSparepart.replace(/,/g, "")
              : 0,
            hargaNettoSparepart:
              hargaSparepart.replace(/,/g, "") -
              (hargaSparepart.replace(/,/g, "") *
                discountSparepart.replace(/,/g, "")) /
                100,
            subtotalSparepart:
              quantitySparepart.replace(/,/g, "") *
                hargaSparepart.replace(/,/g, "") -
              (quantitySparepart.replace(/,/g, "") *
                hargaSparepart.replace(/,/g, "") *
                discountSparepart.replace(/,/g, "")) /
                100 -
              potonganSparepart.replace(/,/g, ""),
            COA: response.data.COA._id,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            kodeCabang: cabangInput,
            id: user._id,
            token: user.token,
          }
        );
        // Update BeliSparepart
        await axios.post(`${tempUrl}/updateBeliSparepart/${id}`, {
          jumlahBeliSparepart:
            parseInt(getBeliSparepart.data.jumlahBeliSparepart) +
            quantitySparepart.replace(/,/g, "") *
              hargaSparepart.replace(/,/g, "") -
            (quantitySparepart.replace(/,/g, "") *
              hargaSparepart.replace(/,/g, "") *
              discountSparepart.replace(/,/g, "")) /
              100 -
            potonganSparepart.replace(/,/g, ""),
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/daftarBeliSparepart/beliSparepart/${id}`);
      } catch (error) {
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Sparepart</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Barang Beli Sparepart
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form
          noValidate
          validated={validated}
          onSubmit={saveBeliSparepartChild}
        >
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Cabang :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={cabangOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && cabangInput.length === 0 && true}
                            helperText={
                              error &&
                              cabangInput.length === 0 &&
                              "Cabang harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => {
                          setCabangInput(value.split(" ", 1)[0]);
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Kode Sparepart <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={sparepartOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeSparepart.length === 0 && true}
                            helperText={
                              error &&
                              kodeSparepart.length === 0 &&
                              "Kode Sparepart harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => {
                          if (value) {
                            getSparepart(`${value.split(" |", 1)[0]}`);
                          } else {
                            setNamaSparepart("");
                            setSatuanSparepart("");
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama Sparepart :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={namaSparepart} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Satuan Sparepart :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={satuanSparepart} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Quantity <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <NumericFormat
                        value={quantitySparepart}
                        thousandSeparator
                        customInput={Form.Control}
                        onValueChange={(values) => {
                          setQuantitySparepart(values.formattedValue);
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Harga <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <NumericFormat
                        value={hargaSparepart}
                        thousandSeparator
                        customInput={Form.Control}
                        onValueChange={(values) => {
                          setHargaSparepart(values.formattedValue);
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Discount (%) :
                    </Form.Label>
                    <Col sm="9">
                      <NumericFormat
                        value={discountSparepart}
                        thousandSeparator
                        customInput={Form.Control}
                        onValueChange={(values) => {
                          setDiscountSparepart(values.formattedValue);

                          let tempHargaSparepart = hargaSparepart.replace(
                            /,/g,
                            ""
                          );

                          // harga * diskon / 100 = potongan
                          setPotonganSparepart(
                            (
                              (tempHargaSparepart *
                                values.formattedValue.replace(/,/g, "")) /
                              100
                            ).toLocaleString("en-US")
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Potongan (Rp.) :
                    </Form.Label>
                    <Col sm="9">
                      <NumericFormat
                        value={potonganSparepart}
                        thousandSeparator
                        customInput={Form.Control}
                        onValueChange={(values) => {
                          setPotonganSparepart(values.formattedValue);

                          let tempHargaSparepart = hargaSparepart.replace(
                            /,/g,
                            ""
                          );

                          // diskon = potongan / harga * 100
                          setDiscountSparepart(
                            (
                              (values.formattedValue.replace(/,/g, "") /
                                tempHargaSparepart) *
                              100
                            ).toLocaleString("en-US")
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Harga Netto :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={(
                          hargaSparepart.replace(/,/g, "") -
                          (hargaSparepart.replace(/,/g, "") *
                            discountSparepart.replace(/,/g, "")) /
                            100
                        ).toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Subtotal :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={(
                          quantitySparepart.replace(/,/g, "") *
                            hargaSparepart.replace(/,/g, "") -
                          (quantitySparepart.replace(/,/g, "") *
                            hargaSparepart.replace(/,/g, "") *
                            discountSparepart.replace(/,/g, "")) /
                            100
                        ).toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={textFieldStyle}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                navigate(`/daftarBeliSparepart/beliSparepart/${id}`)
              }
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TambahBeliChildSparepart;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const textFieldContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const textFieldStyle = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const hargaContainer = {
  marginTop: 2.5,
};

const hargaText = {
  fontWeight: "500",
  color: "gray",
};

const hargaTextField = {
  display: "flex",
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  Paper,
  Snackbar,
  Alert,
  Autocomplete,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import { NumericFormat } from "react-number-format";

const UbahBeliChildSparepart = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { id, idBeliChildSparepart } = useParams();
  const navigate = useNavigate();
  const [jumlahBeliSparepart, setJumlahBeliSparepart] = useState("");
  const [kodeSparepart, setKodeSparepart] = useState("");
  const [kodeSparepartLama, setKodeSparepartLama] = useState("");
  const [namaSparepart, setNamaSparepart] = useState("");
  const [satuanSparepart, setSatuanSparepart] = useState("");
  const [quantitySparepart, setQuantitySparepart] = useState("");
  const [hargaSparepart, setHargaSparepart] = useState("");
  const [discountSparepart, setDiscountSparepart] = useState("");
  const [potonganSparepart, setPotonganSparepart] = useState("");
  const [hargaNettoSparepart, setHargaNettoSparepart] = useState("");
  const [subtotalSparepart, setSubtotalSparepart] = useState("");
  const [subtotalSparepartLama, setSubtotalSparepartLama] = useState("");

  const [spareparts, setSpareparts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const sparepartOptions = spareparts.map((sparepart) => ({
    label: `${sparepart.kodeSparepart} - ${sparepart.namaSparepart}`,
  }));

  useEffect(() => {
    getSpareparts();
    getUserById();
  }, []);

  const getSpareparts = async () => {
    setLoading(true);
    const allSpareparts = await axios.post(`${tempUrl}/spareparts`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setSpareparts(allSpareparts.data);
    setLoading(false);
  };

  const getSparepart = async (idSparepart) => {
    const allSparepartsByKode = await axios.post(`${tempUrl}/sparepartsKode`, {
      kodeSparepart: idSparepart,
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    if (allSparepartsByKode.data) {
      setNamaSparepart(allSparepartsByKode.data.namaSparepart);
      setSatuanSparepart(allSparepartsByKode.data.satuanSparepart);
    }
    setKodeSparepart(idSparepart);
  };

  const getUserById = async () => {
    setLoading(true);
    if (id) {
      const beliSparepart = await axios.post(
        `${tempUrl}/beliSpareparts/${id}`,
        {
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        }
      );
      setJumlahBeliSparepart(beliSparepart.data.jumlahBeliSparepart);

      if (beliSparepart.data.isPost) {
        navigate(
          `/daftarBeliSparepart/beliSparepart/${id}/${idBeliChildSparepart}`
        );
      }

      const response = await axios.post(
        `${tempUrl}/daftarStokSpareparts/${idBeliChildSparepart}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setKodeSparepart(response.data.kodeSparepart);
      setKodeSparepartLama(response.data.kodeSparepart);
      setNamaSparepart(response.data.namaSparepart);
      setSatuanSparepart(`${response.data.satuanSparepart}`);
      setQuantitySparepart(`${response.data.quantitySparepart}`);
      setHargaSparepart(`${response.data.hargaSparepart}`);
      setDiscountSparepart(`${response.data.discountSparepart}`);
      setPotonganSparepart(`${response.data.potonganSparepart}`);
      setHargaNettoSparepart(`${response.data.hargaNettoSparepart}`);
      setSubtotalSparepart(`${response.data.subtotalSparepart}`);
      setSubtotalSparepartLama(`${response.data.subtotalSparepart}`);
    }
    setLoading(false);
  };

  const updateBeliSparepartChildSparepart = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation = kodeSparepart.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);

        // Get Sparepart/Merk
        const findSparepart = await axios.post(`${tempUrl}/sparepartsKode`, {
          kodeCabang: user.cabang._id,
          kodeSparepart,
          id: user._id,
          token: user.token,
        });

        let tempJumlahBeliSparepart =
          jumlahBeliSparepart -
          parseInt(subtotalSparepartLama.replace(/,/g, "")) +
          parseInt(
            quantitySparepart.replace(/,/g, "") *
              hargaSparepart.replace(/,/g, "") -
              (quantitySparepart.replace(/,/g, "") *
                hargaSparepart.replace(/,/g, "") *
                discountSparepart.replace(/,/g, "")) /
                100 -
              potonganSparepart.replace(/,/g, "")
          );

        // Update Daftar Stok
        await axios.post(
          `${tempUrl}/updateDaftarStokSparepart/${idBeliChildSparepart}`,
          {
            merk: findSparepart.data.merk,
            kodeSparepart: findSparepart.data.kodeSparepart,
            namaSparepart: findSparepart.data.namaSparepart,
            satuanSparepart: findSparepart.data.satuanSparepart,
            quantitySparepart: quantitySparepart.replace(/,/g, ""),
            hargaSparepart: hargaSparepart.replace(/,/g, ""),
            discountSparepart: discountSparepart.replace(/,/g, ""),
            potonganSparepart: potonganSparepart.replace(/,/g, ""),
            hargaNettoSparepart:
              hargaSparepart.replace(/,/g, "") -
              (hargaSparepart.replace(/,/g, "") *
                discountSparepart.replace(/,/g, "")) /
                100,
            subtotalSparepart:
              quantitySparepart.replace(/,/g, "") *
                hargaSparepart.replace(/,/g, "") -
              (quantitySparepart.replace(/,/g, "") *
                hargaSparepart.replace(/,/g, "") *
                discountSparepart.replace(/,/g, "")) /
                100 -
              potonganSparepart.replace(/,/g, ""),
            tglUpdate: current_date,
            jamUpdate: current_time,
            userUpdate: user.username,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          }
        );

        // Update Beli Sparepart
        await axios.post(`${tempUrl}/updateBeliSparepart/${id}`, {
          jumlahBeliSparepart: tempJumlahBeliSparepart,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });

        setLoading(false);
        navigate(
          `/daftarBeliSparepart/beliSparepart/${id}/${idBeliChildSparepart}`
        );
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box>
        <Typography color="#757575">Sparepart</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Ubah Barang Beli Sparepart
        </Typography>
        <Divider sx={dividerStyle} />
        <Paper sx={contentContainer} elevation={12}>
          <Form
            noValidate
            validated={validated}
            onSubmit={updateBeliSparepartChildSparepart}
          >
            <Box sx={[textFieldContainer, spacingTop]}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Sparepart <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={sparepartOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodeSparepart.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodeSparepart.length === 0 &&
                                "Kode Sparepart harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onChange={(e, value) => {
                            console.log(value);
                            if (value) {
                              getSparepart(`${value.label.split(" -", 1)[0]}`);
                            } else {
                              setNamaSparepart("");
                              setSatuanSparepart("");
                            }
                          }}
                          inputValue={kodeSparepart}
                          value={kodeSparepartLama}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Sparepart :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaSparepart} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Satuan Sparepart :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={satuanSparepart} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Quantity <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <NumericFormat
                          value={quantitySparepart}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setQuantitySparepart(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Harga <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <NumericFormat
                          value={hargaSparepart}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setHargaSparepart(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Discount (%) <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <NumericFormat
                          value={discountSparepart}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setDiscountSparepart(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Potongan (Rp.) <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <NumericFormat
                          value={potonganSparepart}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setPotonganSparepart(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Harga Netto :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={(
                            hargaSparepart.replace(/,/g, "") -
                            (hargaSparepart.replace(/,/g, "") *
                              discountSparepart.replace(/,/g, "")) /
                              100
                          ).toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Subtotal :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={(
                            quantitySparepart.replace(/,/g, "") *
                              hargaSparepart.replace(/,/g, "") -
                            (quantitySparepart.replace(/,/g, "") *
                              hargaSparepart.replace(/,/g, "") *
                              discountSparepart.replace(/,/g, "")) /
                              100 -
                            potonganSparepart.replace(/,/g, "")
                          ).toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  navigate(
                    `/daftarBeliSparepart/beliSparepart/${id}/${idBeliChildSparepart}`
                  )
                }
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Ubah
              </Button>
            </Box>
          </Form>
        </Paper>
        {error && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={alertBox}>
              Data belum terisi semua!
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
};

export default UbahBeliChildSparepart;

const subTitleText = {
  fontWeight: "900",
};

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const colorRed = {
  color: "red",
};

const alertBox = {
  width: "100%",
};

import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Col, Row, Form } from "react-bootstrap";

const Posting = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let [inputDariTgl, setInputDariTgl] = useState(
    new Date(user.periode.periodeAwal)
  );
  let [inputSampaiTgl, setInputSampaiTgl] = useState(
    new Date(user.periode.periodeAkhir)
  );
  const [persenLoading, setPersenLoading] = useState("0");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openErrorInput, setOpenErrorInput] = useState(false);
  const [vertical] = useState("bottom");
  const [horizontal] = useState("center");

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseErrorInput = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorInput(false);
  };

  const postingPeriode = async () => {
    let countNeracaSaldo = 0;
    let countNeracaSaldoKonsolidasi = 0;
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    var lastday = function (year, month) {
      let date = new Date(year, month + 1, 0);
      return date.getDate();
    };

    var newDariTgl = new Date(inputDariTgl);
    var newSampaiTgl = new Date(inputSampaiTgl);
    let isFailedValidation = inputDariTgl === null || inputSampaiTgl === null;
    let isDariTglBiggerThanSampaiTgl = newDariTgl > newSampaiTgl;
    if (isFailedValidation) {
      setError(true);
    } else if (isDariTglBiggerThanSampaiTgl) {
      setOpenErrorInput(true);
    } else {
      setLoading(true);
      let dariTgl =
        newDariTgl.getFullYear() +
        "-" +
        (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        newDariTgl.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
      let sampaiTgl =
        newSampaiTgl.getFullYear() +
        "-" +
        (newSampaiTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        newSampaiTgl.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
      let tempPersenLoading = parseInt(persenLoading);

      // Jurnal Posting Pembelian
      if (user.ppnDipisah) {
        // Jurnal Pembelian dengan PPN
        await axios.post(
          `${tempUrl}/saveJurnalPostingPembelianRebuildOnlyIsPost`,
          {
            dariTgl,
            sampaiTgl,
            persediaanMotorBaru: setting.persediaanMotorBaru,
            ppnMasukkan: setting.ppnMasukkan,
            hutangDagang: setting.hutangDagang,
            persediaanMotorBekas: setting.persediaanMotorBekas,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
      } else {
        // Jurnal Pembelian PPN digabung dengan Persediaan Barang
        await axios.post(
          `${tempUrl}/saveJurnalPostingPembelianNoPpnRebuildOnlyIsPost`,
          {
            dariTgl,
            sampaiTgl,
            persediaanMotorBaru: setting.persediaanMotorBaru,
            ppnMasukkan: setting.ppnMasukkan,
            hutangDagang: setting.hutangDagang,
            persediaanMotorBekas: setting.persediaanMotorBekas,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
      }
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      let errorPosting = false;
      // Jurnal Posting Penjualan
      await axios
        .post(`${tempUrl}/saveJurnalPostingPenjualanRebuild`, {
          dariTgl,
          sampaiTgl,
          persediaanMotorBaru: setting.persediaanMotorBaru,
          uangTunaiKasir: setting.uangTunaiKasir,
          piutangDagang: setting.piutangDagang,
          penjualanMotor: setting.penjualanMotor,
          hppMotor: setting.hppMotor,
          persediaanMotorBekas: setting.persediaanMotorBekas,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        })
        .catch((error) => {
          alert(error.response.data.message);
          errorPosting = true;
          return;
        });
      if (errorPosting === true) {
        return;
      }
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Penarikan
      // await axios.post(`${tempUrl}/saveJurnalPostingPenarikanRebuild`, {
      //   dariTgl,
      //   sampaiTgl,
      //   piutangDagang: setting.piutangDagang,
      //   persediaanMotorBekas: setting.persediaanMotorBekas,
      //   tglInput: current_date,
      //   jamInput: current_time,
      //   userInput: user.username,
      //   id: user._id,
      //   token: user.token,
      //   kodeCabang: user.cabang._id,
      // });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Angsuran
      // await axios.post(`${tempUrl}/saveJurnalPostingAngsuranRebuild`, {
      //   dariTgl,
      //   sampaiTgl,
      //   uangTunaiKasir: setting.uangTunaiKasir,
      //   piutangDagang: setting.piutangDagang,
      //   pendapatanBungaAngsuran: setting.pendapatanBungaAngsuran,
      //   pendapatanDenda: setting.pendapatanDenda,
      //   pendapatanJemputan: setting.pendapatanJemputan,
      //   pendapatanBiayaTarik: setting.pendapatanBiayaTarik,
      //   tglInput: current_date,
      //   jamInput: current_time,
      //   userInput: user.username,
      //   id: user._id,
      //   token: user.token,
      //   kodeCabang: user.cabang._id,
      // });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Angsuran Beda Cabang
      // await axios.post(
      //   `${tempUrl}/saveJurnalPostingAngsuranBedaCabangRebuild`,
      //   {
      //     dariTgl,
      //     sampaiTgl,
      //     tglInput: current_date,
      //     jamInput: current_time,
      //     userInput: user.username,
      //     id: user._id,
      //     token: user.token,
      //     kodeCabang: user.cabang._id,
      //   }
      // );
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Kas Masuk
      await axios.post(`${tempUrl}/saveJurnalPostingKasMasukRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Kas Masuk Child
      await axios.post(`${tempUrl}/saveJurnalPostingKasMasukChildRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Kas Keluar
      await axios.post(`${tempUrl}/saveJurnalPostingKasKeluarRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Kas Keluar Child
      await axios.post(`${tempUrl}/saveJurnalPostingKasKeluarChildRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Bank Masuk
      await axios.post(`${tempUrl}/saveJurnalPostingBankMasukRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Bank Masuk Child
      await axios.post(`${tempUrl}/saveJurnalPostingBankMasukChildRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Bank Keluar
      await axios.post(`${tempUrl}/saveJurnalPostingBankKeluarRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Bank Keluar Child
      await axios.post(`${tempUrl}/saveJurnalPostingBankKeluarChildRebuild`, {
        dariTgl,
        sampaiTgl,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());
      // Jurnal Posting Biaya Perawatan
      await axios.post(`${tempUrl}/saveJurnalPostingBiayaPerawatanRebuild`, {
        dariTgl,
        sampaiTgl,
        persediaanMotorBaru: setting.persediaanMotorBaru,
        persediaanMotorBekas: setting.persediaanMotorBekas,
        tglInput: current_date,
        jamInput: current_time,
        userInput: user.username,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());

      // Update Periode Lama
      newDariTgl = new Date(dariTgl);
      let tempDariTgl =
        newDariTgl.getFullYear() +
        "-" +
        (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        "01";
      dariTgl = tempDariTgl;

      lastday = function (year, month) {
        let date = new Date(year, month + 1, 0);
        return date.getDate();
      };
      sampaiTgl =
        newDariTgl.getFullYear() +
        "-" +
        (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        lastday(newDariTgl.getFullYear(), newDariTgl.getMonth());

      const lastNeracaSaldo = await axios.post(`${tempUrl}/lastNeracaSaldo`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      // Konsolidasi
      const lastNeracaSaldoKonsolidasi = await axios.post(
        `${tempUrl}/lastNeracaSaldo`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: setting.kodeCabangKonsolidasi,
        }
      );
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());

      let datePartsLastNeracaSaldo = lastNeracaSaldo.data.bulan.split("-");
      let tempTglLastNeracaSaldo = new Date(
        +datePartsLastNeracaSaldo[2],
        (datePartsLastNeracaSaldo[1] - 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        (+datePartsLastNeracaSaldo[0]).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
      );
      let diffMonthNeracaSaldoLast = monthDiff(
        tempTglLastNeracaSaldo,
        newDariTgl
      );

      // Konsolidasi
      let datePartsLastNeracaSaldoKonsolidasi =
        lastNeracaSaldoKonsolidasi.data.bulan.split("-");
      let tempTglLastNeracaSaldoKonsolidasi = new Date(
        +datePartsLastNeracaSaldoKonsolidasi[2],
        (datePartsLastNeracaSaldoKonsolidasi[1] - 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
        (+datePartsLastNeracaSaldoKonsolidasi[0]).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
      );
      let diffMonthNeracaSaldoLastKonsolidasi = monthDiff(
        tempTglLastNeracaSaldoKonsolidasi,
        newDariTgl
      );

      // Create Lost Neraca Saldo
      for (let i = 0; i < diffMonthNeracaSaldoLast; i++) {
        // Make Last Neraca Saldo
        await axios.post(`${tempUrl}/saveLastNeracaSaldoRebuild`, {
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });

        let tempDariTgl = new Date(
          +datePartsLastNeracaSaldo[2],
          (datePartsLastNeracaSaldo[1] - 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          (+datePartsLastNeracaSaldo[0]).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        );
        let newDariTgl = new Date(
          tempDariTgl.setMonth(tempDariTgl.getMonth() + 1)
        );
        tempDariTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          "01";
        let tempSampaiTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          lastday(newDariTgl.getFullYear(), newDariTgl.getMonth());

        // Update Neraca Saldo Jurnal Posting
        await axios.post(`${tempUrl}/saveJurnalPostingNeracaSaldoRebuild`, {
          dariTgl: tempDariTgl,
          sampaiTgl: tempSampaiTgl,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
      }

      // Konsolidasi
      for (let i = 0; i < diffMonthNeracaSaldoLastKonsolidasi; i++) {
        // Make Last Neraca Saldo
        await axios.post(`${tempUrl}/saveLastNeracaSaldoRebuild`, {
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: setting.kodeCabangKonsolidasi,
        });

        let tempDariTgl = new Date(
          +datePartsLastNeracaSaldo[2],
          (datePartsLastNeracaSaldo[1] - 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          (+datePartsLastNeracaSaldo[0]).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        );
        let newDariTgl = new Date(
          tempDariTgl.setMonth(tempDariTgl.getMonth() + 1)
        );
        tempDariTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          "01";
        let tempSampaiTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          lastday(newDariTgl.getFullYear(), newDariTgl.getMonth());

        // Update Neraca Saldo Jurnal Posting
        await axios.post(
          `${tempUrl}/saveJurnalPostingNeracaSaldoKonsolidasiRebuild`,
          {
            dariTgl: tempDariTgl,
            sampaiTgl: tempSampaiTgl,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            id: user._id,
            token: user.token,
            kodeCabang: setting.kodeCabangKonsolidasi,
          }
        );
      }
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());

      const allNeracaSaldo = await axios.post(`${tempUrl}/neracaSaldos`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      // Konsolidasi
      const allNeracaSaldoKonsolidasi = await axios.post(
        `${tempUrl}/neracaSaldos`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: setting.kodeCabangKonsolidasi,
        }
      );
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());

      // Delete Neraca Saldo Bigger Date
      for (let i = 0; i < allNeracaSaldo.data.length; i++) {
        var dateParts = allNeracaSaldo.data[i].bulan.split("-");
        let tempTglNeracaSaldo = new Date(
          `${+dateParts[2]}-
          ${dateParts[1].toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}-
          ${(+dateParts[0] + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`
        );
        let tempDariTgl = new Date(dariTgl);
        let tglBigger = tempTglNeracaSaldo >= tempDariTgl;
        if (tglBigger) {
          // Delete tglBigger Neraca Saldo
          await axios.post(
            `${tempUrl}/deleteNeracaSaldo/${allNeracaSaldo.data[i]._id}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          countNeracaSaldo++;
        }
      }

      // Delete Neraca Saldo Konsolidasi Bigger Date
      for (let i = 0; i < allNeracaSaldoKonsolidasi.data.length; i++) {
        var dateParts = allNeracaSaldoKonsolidasi.data[i].bulan.split("-");
        let tempTglNeracaSaldo = new Date(
          `${+dateParts[2]}-
          ${dateParts[1].toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}-
          ${(+dateParts[0] + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`
        );
        let tempDariTgl = new Date(dariTgl);
        let tglBigger = tempTglNeracaSaldo >= tempDariTgl;
        if (tglBigger) {
          // Delete tglBigger Neraca Saldo
          await axios.post(
            `${tempUrl}/deleteNeracaSaldo/${allNeracaSaldoKonsolidasi.data[i]._id}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: setting.kodeCabangKonsolidasi,
            }
          );
          countNeracaSaldoKonsolidasi++;
        }
      }
      tempPersenLoading += 5;
      setPersenLoading(tempPersenLoading.toString());

      // Replace Neraca Saldo Bigger Date
      for (let i = 0; i < countNeracaSaldo; i++) {
        // Make Last Neraca Saldo
        await axios.post(`${tempUrl}/saveLastNeracaSaldoRebuild`, {
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Update Neraca Saldo Jurnal Posting
        await axios.post(`${tempUrl}/saveJurnalPostingNeracaSaldoRebuild`, {
          dariTgl,
          sampaiTgl,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });

        let tempDariTgl = new Date(dariTgl);
        let newDariTgl = new Date(
          tempDariTgl.setMonth(tempDariTgl.getMonth() + 1)
        );
        tempDariTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          "01";
        dariTgl = tempDariTgl;

        sampaiTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          lastday(newDariTgl.getFullYear(), newDariTgl.getMonth());
      }

      dariTgl =
        newDariTgl.getFullYear() +
        "-" +
        (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        newDariTgl.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
      sampaiTgl =
        newSampaiTgl.getFullYear() +
        "-" +
        (newSampaiTgl.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        newSampaiTgl.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });

      // Replace Neraca Saldo Konsolidasi Bigger Date
      for (let i = 0; i < countNeracaSaldoKonsolidasi; i++) {
        // Make Last Neraca Saldo
        await axios.post(`${tempUrl}/saveLastNeracaSaldoRebuild`, {
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
          kodeCabang: setting.kodeCabangKonsolidasi,
        });

        // Update Neraca Saldo Jurnal Posting
        await axios.post(
          `${tempUrl}/saveJurnalPostingNeracaSaldoKonsolidasiRebuild`,
          {
            dariTgl,
            sampaiTgl,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            id: user._id,
            token: user.token,
            kodeCabang: setting.kodeCabangKonsolidasi,
          }
        );

        let tempDariTgl = new Date(dariTgl);
        let newDariTgl = new Date(
          tempDariTgl.setMonth(tempDariTgl.getMonth() + 1)
        );
        tempDariTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          "01";
        dariTgl = tempDariTgl;

        sampaiTgl =
          newDariTgl.getFullYear() +
          "-" +
          (newDariTgl.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          lastday(newDariTgl.getFullYear(), newDariTgl.getMonth());
      }
      tempPersenLoading = 100;
      setPersenLoading(tempPersenLoading.toString());

      setLoading(false);
      setOpen(true);
      setPersenLoading("0");
    }
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Accounting</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Posting
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(user.periode.periodeAwal)}
                  maxDate={new Date(user.periode.periodeAkhir)}
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(user.periode.periodeAwal)}
                  maxDate={new Date(user.periode.periodeAkhir)}
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <Button
          variant="contained"
          startIcon={<DriveFileRenameOutlineIcon />}
          onClick={() => {
            if (inputSampaiTgl === null || inputDariTgl === null) {
              setError(true);
            } else postingPeriode();
          }}
        >
          POSTING
        </Button>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Jurnal berhasil diposting!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorInput}
        autoHideDuration={6000}
        onClose={handleCloseErrorInput}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseErrorInput}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Sampai Tanggal tidak boleh lebih besar Dari Tanggal
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Posting;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
  mb: 2,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};

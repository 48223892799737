import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  Divider,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const Point6Bulan = () => {
  const tableRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  var date = new Date();
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [surveyors, setSurveyors] = useState([]);
  const [tunggakan, setTunggakan] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const columns = [
    { title: "No", field: "no" }, //1
    { title: "Kontrak", field: "noJual" }, //2
    { title: "Tanggal", field: "tglBayarFormatted" }, //3
    { title: "Nama", field: "namaRegister" }, //4
    { title: "Alamat", field: "almRegister" }, //5
    { title: "No. Telp/Hp", field: "tlpRegister" }, //6
    { title: "No. BP", field: "nopol" }, //7
    { title: "JW", field: "tenor" }, //8
    { title: "Ang", field: "angPerBulan" }, //9
    { title: "Ke", field: "bayarKe" }, //10
    { title: "P", field: "p" }, //11
    { title: "PP", field: "pp" }, //12
    { title: "Pl", field: "pl" }, //13
    { title: "PK", field: "pk" }, //14
    { title: ">90", field: "lebihDariSamaDengan90" }, //15
    { title: "TTL", field: "total" }, //16
    { title: "Potongan", field: "" }, //17
  ];

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  useEffect(() => {
    getSurveyor();
  }, []);

  const getSurveyor = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(response.data);
    setLoading(false);
  };

  const downloadPdf = async () => {
    if (kodeSurveyor.length === 0) {
      alert("Kode Surveyor tidak boleh kosong!");
      return;
    }
    setLoading(true);

    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let tempPersenLoading = parseInt(persenLoading);
    tempPersenLoading += 10;
    setPersenLoading(tempPersenLoading.toString());

    const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
      kodeSurveyor,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "5000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "10000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "15000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "20000");

    const response = await axios.post(
      `${tempUrl}/penerimaansForLaporanPoint6Bulan`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
        jenisSurveyor: tempSurveyor.data.jenisSurveyor,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    let tempP = 0;
    let tempPp = 0;
    let tempPl = 0;
    let tempPk = 0;
    let tempLebihDariSamaDengan90 = 0;
    let tempTotal = 0;

    for (let result of response.data) {
      tempP += result.p;
      tempPp += result.pp;
      tempPl += result.pl;
      tempPk += result.pk;
      tempLebihDariSamaDengan90 += result.lebihDariSamaDengan90;
      tempTotal += result.total;
    }

    tempPersenLoading += 10;
    setPersenLoading(tempPersenLoading.toString());

    let tempHeight = 35;

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [410, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Point 6 Bulan`, 200, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl}`, 15, 35);
    doc.text(`Sampai Tanggal : ${sampaiTgl}`, 15, 40);
    doc.text(
      `Surveyor : ${`${kodeSurveyor} - ${tempSurveyor.data.namaSurveyor}`}`,
      15,
      50
    );
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 55,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: response.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 30 },
        2: { cellWidth: 25 },
        3: { cellWidth: 40 },
        4: { cellWidth: 60 },
        5: { cellWidth: 40 },
        6: { cellWidth: 25 },
        7: { cellWidth: 15 },
        8: { cellWidth: 20 },
        9: { cellWidth: 15 },
        10: { cellWidth: 15 },
        11: { cellWidth: 15 },
        12: { cellWidth: 15 },
        13: { cellWidth: 15 },
        14: { cellWidth: 15 },
        15: { cellWidth: 15 },
        16: { cellWidth: 15 },
        17: { cellWidth: 10 },
        // etc
      },
    });
    doc.addPage();
    doc.text(`Poin`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(`${tempP.toLocaleString("en-US")}`, 100, tempHeight, {
      align: "right",
    });
    tempHeight += 5;
    doc.text(`Poin Kembali`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(`${tempPk.toLocaleString("en-US")}`, 100, tempHeight, {
      align: "right",
    });
    tempHeight += 5;
    doc.text(`P. Program`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(`${tempPp.toLocaleString("en-US")}`, 100, tempHeight, {
      align: "right",
    });
    tempHeight += 5;
    doc.text(`>= 90 Hari`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(
      `${tempLebihDariSamaDengan90.toLocaleString("en-US")}`,
      100,
      tempHeight,
      {
        align: "right",
      }
    );
    tempHeight += 5;
    doc.line(30, tempHeight, 100, tempHeight);
    tempHeight += 5;
    doc.text(`Sisa Poin`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    let tempSisaPoin = tempP + tempPk + tempPp + tempLebihDariSamaDengan90;
    doc.text(`${tempSisaPoin.toLocaleString("en-US")}`, 100, tempHeight, {
      align: "right",
    });
    tempHeight += 5;
    doc.text(`Nilai Poin`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(
      `${setting.nilaiPointSetting.toLocaleString("en-US")}`,
      100,
      tempHeight,
      {
        align: "right",
      }
    );
    tempHeight += 5;
    doc.text(`Total`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(
      `${(tempSisaPoin * setting.nilaiPointSetting).toLocaleString("en-US")}`,
      100,
      tempHeight,
      {
        align: "right",
      }
    );
    tempHeight += 5;
    doc.text(`Potongan`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(``, 100, tempHeight, {
      align: "right",
    });
    tempHeight += 5;
    doc.text(`Total Bersih`, 30, tempHeight);
    doc.text(`:`, 70, tempHeight);
    doc.text(
      `${(tempSisaPoin * setting.nilaiPointSetting).toLocaleString("en-US")}`,
      100,
      tempHeight,
      {
        align: "right",
      }
    );
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Point 6 Bulan
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Kode Surveyor :
              </Form.Label>
              <Col sm="8">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={surveyorOptions}
                  defaultValue={kodeSurveyor}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && kodeSurveyor.length === 0 && true}
                      helperText={
                        error &&
                        kodeSurveyor.length === 0 &&
                        "Kode Surveyor harus diisi!"
                      }
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) =>
                    setKodeSurveyor(value.split(" ", 1)[0])
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
        </ButtonGroup>
      </Box>
      <table ref={tableRef} style={{ visibility: "hidden" }}>
        <tbody>
          <tr>
            <th>Laporan Tunggakan</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>No</th>
            <th>No. Jual</th>
            <th>Nama</th>
            <th>Alamat</th>
            <th>Telepon</th>
            <th>Tipe</th>
            <th>Angsuran</th>
            <th>Surveyor</th>
            <th>Tenor</th>
            <th>Sisa Bulan</th>
            <th>Jatuh Tempo</th>
            <th>MD1</th>
            <th>MD2</th>
            <th>MD3</th>
            <th>SP</th>
            <th>ST</th>
            <th>HR</th>
          </tr>
          {tunggakan.map((item, i) => (
            <tr>
              <th>{item.no}</th>
              <th>{item.noJual}</th>
              <th>{item.namaRegister}</th>
              <th>{item.almRegister}</th>
              <th>{item.tlpRegister}</th>
              <th>{item.tipe}</th>
              <th>{item.angPerBulan}</th>
              <th>{item.kodeSurveyor}</th>
              <th>{item.tenor}</th>
              <th>{item.sisaBulan}</th>
              <th>{item.tglJatuhTempo}</th>
              <th>{item.tglMd1}</th>
              <th>{item.tglMd2}</th>
              <th>{item.tglMd3}</th>
              <th>{item.tglSpTerakhir}</th>
              <th>{item.tglStTerakhir}</th>
              <th>{item.hr}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default Point6Bulan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Col, Row, Form } from "react-bootstrap";

const TampilBeliChildSparepart = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { id, idBeliChildSparepart } = useParams();
  const navigate = useNavigate();
  const [idStokSparepart, setIdStokSparepart] = useState("");
  const [noBeliSparepart, setNoBeliSparepart] = useState("");
  const [kodeSparepart, setKodeSparepart] = useState("");
  const [namaSparepart, setNamaSparepart] = useState("");
  const [satuanSparepart, setSatuanSparepart] = useState("");
  const [quantitySparepart, setQuantitySparepart] = useState("");
  const [hargaSparepart, setHargaSparepart] = useState("");
  const [discountSparepart, setDiscountSparepart] = useState("");
  const [potonganSparepart, setPotonganSparepart] = useState("");
  const [hargaNettoSparepart, setHargaNettoSparepart] = useState("");
  const [subtotalSparepart, setSubtotalSparepart] = useState("");
  const [tglBeliSparepartDate, setTglBeliSparepartDate] = useState();
  const [isPost, setIsPost] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADM" && isPost === false;
  let isEditableAdmin =
    user.tipeUser === "ADM" &&
    tglBeliSparepartDate?.getFullYear() === today.getFullYear() &&
    tglBeliSparepartDate?.getMonth() === today.getMonth() &&
    tglBeliSparepartDate?.getDate() === today.getDate() &&
    isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/daftarStokSpareparts/${idBeliChildSparepart}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setIdStokSparepart(response.data._id);
      setNoBeliSparepart(response.data.noBeliSparepart);
      setKodeSparepart(response.data.kodeSparepart);
      setNamaSparepart(response.data.namaSparepart);
      setSatuanSparepart(response.data.satuanSparepart);
      setQuantitySparepart(response.data.quantitySparepart);
      setHargaSparepart(response.data.hargaSparepart);
      setDiscountSparepart(response.data.discountSparepart);
      setPotonganSparepart(response.data.potonganSparepart);
      setHargaNettoSparepart(response.data.hargaNettoSparepart);
      setSubtotalSparepart(response.data.subtotalSparepart);
      setIsPost(response.data.isPost);
      setTglBeliSparepartDate(new Date(response.data.tanggalBeliSparepart));
    }
  };

  const deleteBeliSparepartChildSparepart = async (id) => {
    try {
      setLoading(true);
      // Get BeliSparepart
      const getBeliSparepart = await axios.post(
        `${tempUrl}/beliSpareparts/${id}`,
        {
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        }
      );
      // Update BeliSparepart
      await axios.post(`${tempUrl}/updateBeliSparepart/${id}`, {
        jumlahBeliSparepart:
          parseInt(getBeliSparepart.data.jumlahBeliSparepart) -
          parseInt(subtotalSparepart),
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      // Delete Daftar Stok Sparepart
      await axios.post(
        `${tempUrl}/deleteDaftarStokSparepart/${idStokSparepart}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setNoBeliSparepart("");
      setKodeSparepart("");
      setNamaSparepart("");
      setSatuanSparepart("");
      setQuantitySparepart("");
      setHargaSparepart("");
      setDiscountSparepart("");
      setPotonganSparepart("");
      setHargaNettoSparepart("");
      setSubtotalSparepart("");
      setIsPost("");
      setTglBeliSparepartDate("");
      setLoading(false);
      navigate(`/daftarBeliSparepart/beliSparepart/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarBeliSparepart/beliSparepart/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Sparepart</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Barang Beli Sparepart
        </Typography>
        {isEditable && (
          <Box sx={deleteButtonContainer}>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {`Yakin ingin menghapus data ${kodeSparepart}?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteBeliSparepartChildSparepart(id)}>
                  Ok
                </Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
            <ButtonGroup variant="contained">
              <Button
                color="primary"
                startIcon={<EditIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(
                    `/daftarBeliSparepart/beliSparepart/${id}/${idBeliChildSparepart}/edit`
                  );
                }}
              >
                Ubah
              </Button>
              <Button
                color="error"
                startIcon={<DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleClickOpen}
              >
                Hapus
              </Button>
            </ButtonGroup>
          </Box>
        )}
        <Divider sx={dividerStyle} />
        <Box sx={[textFieldContainer, spacingTop]}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No Beli :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noBeliSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode Sparepart :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={kodeSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama Sparepart :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Satuan Sparepart :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={satuanSparepart} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Quantity :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={quantitySparepart.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Harga :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={hargaSparepart.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Discount :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={discountSparepart.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Potongan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={potonganSparepart.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Harga Netto :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={hargaNettoSparepart.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Subtotal :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={subtotalSparepart.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TampilBeliChildSparepart;

const subTitleText = {
  fontWeight: "900",
};

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableRegister } from "../../../components/ShowTable";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const TampilRegister = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isRegisterExist, setIsRegisterExist] = useState(false);
  const [noRegister, setNoRegister] = useState("");
  const [tanggalRegister, setTanggalRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [almKtpRegister, setAlmKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaIbuKandungRegister, setNamaIbuKandungRegister] = useState("");
  const [pkjRegister, setPkjRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [almPjmRegister, setAlmPjmRegister] = useState("");
  const [tlpPjmRegister, setTlpPjmRegister] = useState("");
  const [hubunganRegister, setHubunganRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [pkjPjmRegister, setPkjPjmRegister] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kelurahanId, setKelurahanId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [registersData, setRegistersData] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { title: "No", field: "no" },
    { title: "No Register", field: "noRegister" },
    { title: "Tanggal", field: "tanggalRegister" },
    { title: "Nama", field: "namaRegister" },
    { title: "Alamat", field: "almRegister" },
    { title: "Telepon", field: "tlpRegister" },
    { title: "No. KTP", field: "noKtpRegister" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getUserById() : setIsRegisterExist(false);
  }, [id]);

  useEffect(() => {
    getRegistersData();
  }, [page, searchTerm]);

  const getRegistersData = async () => {
    setLoading(true);
    try {
      const allRegisters = await axios.post(
        `${tempUrl}/registersPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      setQuery(searchTerm);
      setRegistersData(allRegisters.data.registers);
      setPage(allRegisters.data.page);
      setPages(allRegisters.data.totalPage);
      setRows(allRegisters.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getRegistersForDoc = async (isPdf, isExcel) => {
    setLoading(true);
    const allRegisterForDoc = await axios.post(`${tempUrl}/registersForDoc`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setLoading(false);
    if (isPdf) {
      downloadPdf(allRegisterForDoc.data);
    } else if (isExcel) {
      downloadExcel(allRegisterForDoc.data);
    }
  };

  const getUserById = async () => {
    setLoading(true);
    if (id) {
      const pickedRegister = await axios.post(`${tempUrl}/registers/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsRegisterExist(true);
      setNoRegister(pickedRegister.data.noRegister);
      setTanggalRegister(pickedRegister.data.tanggalRegister);
      setNamaRegister(pickedRegister.data.namaRegister);
      setAlmRegister(pickedRegister.data.almRegister);
      setTlpRegister(pickedRegister.data.tlpRegister);
      setNoKtpRegister(pickedRegister.data.noKtpRegister);
      setAlmKtpRegister(pickedRegister.data.almKtpRegister);
      setNoKKRegister(pickedRegister.data.noKKRegister);
      setNamaIbuKandungRegister(pickedRegister.data.namaIbuKandungRegister);
      setNamaPjmRegister(pickedRegister.data.namaPjmRegister);
      setAlmPjmRegister(pickedRegister.data.almPjmRegister);
      setTlpPjmRegister(pickedRegister.data.tlpPjmRegister);
      setHubunganRegister(pickedRegister.data.hubunganRegister);
      setNoKtpPjmRegister(pickedRegister.data.noKtpPjmRegister);
      setPkjPjmRegister(
        `${pickedRegister.data.pekerjaanPenjaminId.kodePekerjaan} - ${pickedRegister.data.pekerjaanPenjaminId.namaPekerjaan}`
      );
      setPkjRegister(
        `${pickedRegister.data.pekerjaanId.kodePekerjaan} - ${pickedRegister.data.pekerjaanId.namaPekerjaan}`
      );
      setNamaRefRegister(pickedRegister.data.namaRefRegister);
      setAlmRefRegister(pickedRegister.data.almRefRegister);
      setTlpRefRegister(pickedRegister.data.tlpRefRegister);
      setKelurahanId(
        `${pickedRegister.data.kelurahanId.kodeKelurahan} - ${pickedRegister.data.kelurahanId.namaKelurahan}`
      );
    }
    setLoading(false);
  };

  const deleteRegister = async (id) => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/deleteRegister/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoRegister("");
      setTanggalRegister("");
      setNamaRegister("");
      setAlmRegister("");
      setTlpRegister("");
      setNoKtpRegister("");
      setAlmKtpRegister("");
      setNoKKRegister("");
      setNamaIbuKandungRegister("");
      setPkjRegister("");
      setNamaPjmRegister("");
      setAlmPjmRegister("");
      setTlpPjmRegister("");
      setHubunganRegister("");
      setNoKtpPjmRegister("");
      setPkjPjmRegister("");
      setNamaRefRegister("");
      setAlmRefRegister("");
      setTlpRefRegister("");
      setLoading(false);
      navigate("/register");
      getRegistersData();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Register`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Register`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarRegister.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Register Penjualan
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => getRegistersForDoc(true, false)}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getRegistersForDoc(false, true)}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isRegisterExist}
          addLink={`/register/tambahRegister`}
          editLink={`/register/${id}/edit`}
          deleteUser={deleteRegister}
          nameUser={noRegister}
        />
      </Box>
      {isRegisterExist && (
        <>
          <Divider sx={dividerStyle} />
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tanggal :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tanggalRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKtpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat KTP Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almKtpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KK Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKKRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Ibu Kandung :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaIbuKandungRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Pekerjaan Register :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={pkjRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col></Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kelurahan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kelurahanId} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Hubungan Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={hubunganRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. KTP Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noKtpPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Pekerjaan Penjamin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={pkjPjmRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon Referensi :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpRefRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </>
      )}
      <Divider sx={dividerStyle} />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableRegister currentPosts={registersData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilRegister;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
